.graph-tooltip {
  position: absolute;
  z-index: 50;
  opacity: 0;

  .tooltip-content {
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    padding: 0 0.25rem;
    position: relative;
    color: #000;
    background: rgba(var(--color-base-string), 0.9);
    border: 1px solid var(--color-base);

    &:empty {
      display: none;
    }

    &::after,
    &::before {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-color: transparent;
      border-width: 5px;
    }

    &::before {
      border-color: transparent;
      border-width: 6px;
    }

    &.left {
      &::after,
      &::before {
        right: 100%;
        top: 50%;
      }

      &::after {
        border-right-color: rgba(var(--color-base-string), 0.9);
        margin-top: -5px;
      }

      &::before {
        border-right-color: var(--color-base);
        margin-top: -6px;
      }
    }

    &.bottom {
      &::after,
      &::before {
        top: 100%;
        left: 50%;
      }

      &::after {
        border-top-color: rgba(var(--color-base-string), 0.9);
        margin-left: -5px;
      }

      &::before {
        border-top-color: var(--color-base);
        margin-left: -6px;
      }
    }

    &.right {
      &::after,
      &::before {
        left: 100%;
        top: 50%;
      }

      &::after {
        border-left-color: rgba(var(--color-base-string), 0.9);
        margin-top: -5px;
      }

      &::before {
        border-left-color: var(--color-base);
        margin-top: -6px;
      }
    }

    &.top {
      &::after,
      &::before {
        bottom: 100%;
        left: 50%;
      }

      &::after {
        border-bottom-color: rgba(var(--color-base-string), 0.9);
        margin-left: -5px;
      }

      &::before {
        border-bottom-color: var(--color-base);
        margin-left: -6px;
      }
    }
  }
}

.rowchart {
  min-height: 300px;
  position: relative;

  svg {
    background-color: rgba(var(--color-base-string), 0.2);
  }

  .bar {
    fill: var(--color-base);
    font-size: $f6;

    &:hover {
      fill: rgba(var(--color-base-string), 0.9);
      cursor: pointer;
    }
  }

  .x.axis {
    opacity: 0.5;

    .tick text {
      font-size: $f8;
    }
  }

  .y.axis {
    text-anchor: start;

    .tick text {
      font-size: $f7;
      pointer-events: none;
      color: $color_text;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .tick line,
  .y .domain {
    display: none;
  }
}
